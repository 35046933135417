<template>
  <div>
    <div v-if="(this.payment.Status == 4)" class="mb-5">
      <div class="vx-col w-full">
        <br />
        <vs-button color="green" class="mb-2" v-on:click="handleReversal"
          >Approve</vs-button
        >
      </div>
      <div class="vx-col w-full">
        <br />
        <vs-button  v-if="(this.payment.Status == 4)" color="red" class="mb-2" v-on:click="handleReversalRejected"
          >Reject</vs-button
        >
      </div>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
      class=""
    >
      <template slot="thead">
        <!-- <th width="15%">
                <vs-checkbox color="success" v-on:click="addAllValidate()" v-model="validateAll">Release All</vs-checkbox>
              </th> -->
      </template>
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment">
          <vs-td>
            {{ tr.InvoiceCode }}
          </vs-td>
          <vs-td>
            {{ priceFormat(tr.InvoicePaymentAmount) }}
          </vs-td>
          <vs-td>
            {{ tr.Note }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
import { getPaymentLine } from "../../../../../../services/api/payment";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  props: {
    selected: Object,
    payment: Object,
  },
  components: {
    Datepicker,
  },
  watch: {
    payment() {
      this.reloadData(this.params);
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "created_at",
      },
      header: [
        {
          text: "Invoice",
          value: "invoice_code",
          // width: '5%'
        },
        {
          text: "Payment",
          value: "invoice_payment_amount",
        },
        {
          text: "Note",
          value: "note",
        },
      ],
      uploadReady: true,
      responseData: {},
      selectedData: {},
    };
  },
  mounted() {
    this.reversalDate = new Date();
  },
  methods: {
    reloadData(params) {
      this.$vs.loading();
      this.params = params;
      this.params.payment_id = this.payment.ID;
      //   console.log(params);
      const dataTable = getPaymentLine(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.payment;
          this.responseData = r.data;
          this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("closeDetail",true);
    },
    handleReversal() {
      const params = {
        id_payment: this.payment.ID,
        statusPayment: 5,
      };
      this.$vs.loading();
      console.log("here")
      this.$http
        .post("/api/v1/payment/receive-reversal", params)
        .then((result) => {
          console.log(result);
          
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: result.message,
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });

          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.closeDetail()
        })
        .catch((e) => {});
    },
    handleReversalRejected() {
      const params = {
        id_payment: this.payment.ID,
        statusPayment: 2,
      };
      this.$vs.loading();
      //console.log(params)
      this.$http
        .post("/api/v1/payment/receive-reversal", params)
        .then((result) => {
          console.log(result);
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: result.message,
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
  },
};
</script>
